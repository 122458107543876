export const SpinningWhiteCircleLoader = () => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="spinner-border align-items-center text-center" role="status" style={{width: '2rem', height: '2rem', color: '#ffff'}}>
            <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export const SpinningBlueCircleLoader = () => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="spinner-border align-items-center text-center" role="status" style={{width: '2rem', height: '2rem', color: '#58BBFE'}}>
            <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}