import React, { useState, useContext } from "react";

//router
import { Link, useLocation } from "react-router-dom";

//react-bootstrap
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Nav,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = React.memo(() => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [active, setActive] = useState("");
  //location
  let location = useLocation();
  // console.log(document);

  return (
    <React.Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
      <Nav.Item 
        as="li"
        className="nav-item static-item"
      > 
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Main</span>
            <span
              className="mini-icon"
              data-bs-toggle="tooltip"
              title="Social"
              data-bs-placement="right"
            >
              -
            </span>
          </Link>
        </Nav.Item>
        <Nav.Item 
          as="li"
          className={`${location.pathname === "/" ? "active" : ""} nav-item `}
        >
          <Link
            className={`${location.pathname === "/" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Dashboard</Tooltip>}
            >
                <i className="icon material-symbols-outlined">space_dashboard</i>
            </OverlayTrigger>
            <span className="item-name">Dashboard</span>
          </Link>
        </Nav.Item>
        <Nav.Item 
          as="li"
          className={`${location.pathname === "/" ? "active" : ""} nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/dashboard/app/profile"
                ? "active"
                : ""
            } nav-link`}
            to="/dashboard/app/profile"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>User Management</Tooltip>}
            >
              <i className="icon material-symbols-outlined">person</i>
            </OverlayTrigger>
            <span className="item-name">Profile</span>
          </Link>
        </Nav.Item>
        <Accordion.Item
          as="li"
          eventKey="profile-menu"
          bsPrefix={`nav-item ${active === "profile" ? "active" : ""} `}
          onClick={() => setActive("profile")}
        >
          <CustomToggle
            eventKey="profile-menu"
            onClick={(activeKey) => setActiveMenu(activeKey)}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>User Management</Tooltip>}
            >
              <i className="icon material-symbols-outlined">manage_accounts</i>
            </OverlayTrigger>
            <span className="item-name">User Management</span>
            <i className="right-icon material-symbols-outlined">
              chevron_right
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="profile-menu">
            <ul className="sub-nav">
              <Nav.Item as="li">
                <Link
                  className={`${
                    location.pathname === "/dashboard/app/profile"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="/dashboard/app/profile"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Users</Tooltip>}
                  >
                    <i className="icon material-symbols-outlined">group</i>
                  </OverlayTrigger>
                  <span className="item-name"> Users</span>
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  className={`${
                    location.pathname === "/dashboard/app/profile"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="/dashboard/app/profile"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Roles</Tooltip>}
                  >
                    <i className="icon material-symbols-outlined">id_card</i>
                  </OverlayTrigger>
                  <span className="item-name"> Roles</span>
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  className={`${
                    location.pathname === "/dashboard/app/profile"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="/dashboard/app/profile"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Homeowners</Tooltip>}
                  >
                    <i className="icon material-symbols-outlined">location_away</i>
                  </OverlayTrigger>
                  <span className="item-name"> Homeowners</span>
                </Link>
              </Nav.Item>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>

        <Nav.Item 
          as="li"
        >
          <hr className="hr-horizontal" />
        </Nav.Item>

       <Nav.Item 
          as="li"
          className={`${location.pathname === "/" ? "active" : ""} nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/dashboard/app/profile"
                ? "active"
                : ""
            } nav-link`}
            to="/dashboard/app/profile"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Request Management</Tooltip>}
            >
              <i className="icon material-symbols-outlined">assignment</i>
            </OverlayTrigger>
            <span className="item-name">Request Management</span>
          </Link>
        </Nav.Item>

        <li
          className={`${location.pathname === "/" ? "active" : ""} nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/dashboard/app/profile"
                ? "active"
                : ""
            } nav-link`}
            to="/dashboard/app/profile"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Voting Management</Tooltip>}
            >
              <i className="icon material-symbols-outlined">bar_chart</i>
            </OverlayTrigger>
            <span className="item-name">Voting Management</span>
          </Link>
        </li>

        <Nav.Item as="li">
          <Link
            className={`${
              location.pathname === "/dashboard/app/notification"
                ? "active"
                : ""
            } nav-link `}
            aria-current="page"
            to="/dashboard/app/notification"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Notification Management</Tooltip>}
            >
              <i className="icon material-symbols-outlined">notifications</i>
            </OverlayTrigger>
            <span className="item-name">Notification Management</span>
          </Link>
        </Nav.Item>

        <Nav.Item as="li">
          <Link
            className={`${
              location.pathname === "/dashboard/app/notification"
                ? "active"
                : ""
            } nav-link `}
            aria-current="page"
            to="/dashboard/app/notification"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Blogs & News Management</Tooltip>}
            >
              <i className="icon material-symbols-outlined">full_coverage</i>
            </OverlayTrigger>
            <span className="item-name">Blogs & News Management</span>
          </Link>
        </Nav.Item>

        <Nav.Item as="li">
          <hr className="hr-horizontal" />
        </Nav.Item>

        <Nav.Item as="li">
          <Link
            className={`${
              location.pathname === "/dashboard/app/notification"
                ? "active"
                : ""
            } nav-link `}
            aria-current="page"
            to="/dashboard/app/notification"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Billing And Invoicing</Tooltip>}
            >
              <i className="icon material-symbols-outlined">account_balance</i>
            </OverlayTrigger>
            <span className="item-name">Billing And Invoicing</span>
          </Link>
        </Nav.Item>

        <Nav.Item as="li">
          <hr className="hr-horizontal" />
        </Nav.Item>

        <Nav.Item as="li">
          <Link
            className={`${
              location.pathname === "/system-configuration"
                ? "active"
                : ""
            } nav-link `}
            aria-current="page"
            to="/system-configuration"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>System Configuration</Tooltip>}
            >
              <i className="icon material-symbols-outlined">settings</i>
            </OverlayTrigger>
            <span className="item-name">System Configuration</span>
          </Link>
        </Nav.Item>

        <Nav.Item as="li">
          <Link
            className={`${
              location.pathname === "/dashboard/app/notification"
                ? "active"
                : ""
            } nav-link `}
            aria-current="page"
            to="/dashboard/app/notification"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Exit</Tooltip>}
            >
              <i className="icon material-symbols-outlined">logout</i>
            </OverlayTrigger>
            <span className="item-name">Exit</span>
          </Link>
        </Nav.Item>
      </Accordion>
    </React.Fragment>
  );
});

export default VerticalNav;
