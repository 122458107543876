import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const Pagination = ({page, setPage, perPage, setPerPage}) => {
    const dummyPagerCount = [1,2,3];
    const dummyPerPageCount = [5, 10, 20, 50, 80, 100];
    
    return (
        <div className="d-flex flex-row justify-content-between">
           <div className="btn-group d-flex flex-row align-items-center" role="group">
                <span className="me-2">Show</span>
                <Dropdown>
                    <Dropdown.Toggle 
                        as="div"
                        className="p-2 rounded-3"
                        style={{
                            backgroundColor: '#F3F5F7'
                        }}
                        // as={CustomToggle}
                    >
                        {perPage} {" "}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {dummyPerPageCount?.map((perPageCount, idx) => (
                            <Dropdown.Item 
                                key={idx}
                                className={`${perPage === perPageCount ? 'text-primary' : ''}`}
                                disabled={perPage === perPageCount}
                                onClick={() => setPerPage(perPageCount)}
                            >
                                {perPageCount}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                </div>
            <div>
                <nav aria-label="Page navigation example">
                    <ul className="pagination mb-0">
                        <li className="page-item" onClick={() => setPage(page--)}><Link className="page-link" to="#">Previous</Link></li>
                        {dummyPagerCount?.map((pageNum, idx) => (
                            <li 
                                key={idx}
                                className={`${page === idx ? 'active' : ''} page-item`}
                                onClick={() => setPage(idx)}
                            >
                                <Link className="page-link" to="#">{pageNum}</Link>
                            </li>
                        ))}
                        <li className="page-item" onClick={() => setPage(page++)}><Link className="page-link" to="#">Next</Link></li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Pagination;