import {
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
    Form,
    Nav,
    Tab,
  } from "react-bootstrap";
import Card from "../../components/Card";
import { useEffect, useState } from "react";
import HouseList from "./tabs/houseList";
import AddNewHouse from "./tabs/addNewHouse";
import "../../assets/scss/screens/system-configuration/config-tabs.scss";
import "../../assets/scss/screens/system-configuration/config-tasks-list.scss";
import useDashboardStore from "../../zustand/useDashboardStore";
import { Link } from "react-router-dom";

const SystemConfiguration = () => {
    const [showAddNewHouse, setShowAddNewHouse] = useState(false);
    const [houseDetails, setHouseDetails] = useState(null);

    const {activeTab, setActiveTab} = useDashboardStore();

    useEffect(() => {
        //unmount when activeTab is changed
        return () =>  {
            setShowAddNewHouse(false);
            setHouseDetails(null);
        };
    }, [activeTab]);

    const configTabsMenu = [
        {
            title: "House",
            icon: "home",
        },
        {
            title: "Billing",
            icon: "attach_money",
        },
        {
            title: "ARC",
            icon: "newspaper",
        },
        {
            title: "Violation",
            icon: "report",
        },
        {
            title: "Payments",
            icon: "wallet"
        },
        {
            title: "Charges",
            icon: "receipt"
        },
        {
            title: "Notifications",
            icon: "notifications"
        },
        {
            title: "Vendors",
            icon: "diversity_3"
        }
    ]

    const handleTabBtn = (tab) => {
        if (showAddNewHouse) {
            setShowAddNewHouse(false);
            setActiveTab(tab);
            setHouseDetails(null);
        } else {
            setActiveTab(tab)
        }
    }

    const renderTabs = () => {
        switch (activeTab) {
            case "house":
                return showAddNewHouse ? <AddNewHouse setShowAddNewHouse={setShowAddNewHouse} houseDetails={houseDetails}  /> :  <HouseList setShowAddNewHouse={setShowAddNewHouse} setHouseDetails={setHouseDetails} />;
            case "billing": 
                return <h1>Billing</h1>;
            case "arc":
                return <h1>ARC</h1>;
            case "violation":
                return <h1>Violation</h1>;
            case "payments":
                return <h1>Payments</h1>;
            case "charges":
                return <h1>Charges</h1>;
            case "notifications":
                return <h1>Notifications</h1>;
            case "vendors":
                return <h1>Vendors</h1>;
            default:
                return null;
        }
    }

    return (
        <>
            <Row className="gx-4 py-4">
                <Tab.Container 
                    id="system-configuration" 
                >
                    <Col lg={12}>
                        <Card>
                            <Card.Body className="p-0">
                                <div className="config-tabs user-tabing item-list-tabs">
                                <Nav
                                    as="ul"
                                    variant="pills"
                                    className="profile-feed-items d-flex align-items-center justify-content-center p-0 m-0 rounded"
                                >
                                    {configTabsMenu.map((tab, idx) => (
                                        <Nav.Item as="li" key={idx} className="col-12 col-sm-1">
                                            <Link
                                                role="button"
                                                className={`${
                                                    activeTab === tab?.title?.toLocaleLowerCase() 
                                                    ? 'active' 
                                                    : ''
                                                } d-flex flex-md-column align-items-center flex-row justify-content-center gap-2`}
                                                onClick={() => handleTabBtn(tab?.title?.toLocaleLowerCase())}
                                            >
                                                <span className="icon rounded-3">
                                                <span className="material-symbols-outlined">
                                                    {tab.icon}
                                                </span>
                                                </span>
                                                <p className="mb-0 mt-0 mt-md-3">{tab.title}</p>
                                            </Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={12}>
                       <Tab.Content>
                        {renderTabs()}
                       </Tab.Content>
                    </Col>
                </Tab.Container>
            </Row>
        </>
    )
}

export default SystemConfiguration;