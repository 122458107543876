import { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import useGetHomeownersListApi from "../../../hooks/user-management/useGetHomeownersListApi";
import { SpinningBlueCircleLoader } from "../../../components/common/loaders";
import Pagination from "../../../components/common/pagination";
import { toast } from "react-toastify";
import { useGetApiClient } from "../../../api/useApiClient";
import CreateUpdateHomeownerModal from "../modals/createUpdateHomeownerModal";

const HomeOwnersList = () => {
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [listType, setListType] = useState('active');
    const [showArchivePrompt, setShowArchivePrompt] = useState(false)
    const [toggledArchived, setToggledArchive] = useState(null);
    const [showCreateUpdateModal, setShowCreateUpdateModal] = useState(false);

    const { loading, homeOwnersList } = useGetHomeownersListApi(page, perPage, listType);
    
    const onArchiveToggled = async(e) => {
        e.preventDefault();
        try {
            const { data } = await useGetApiClient.put(`/admin/homeowner/archive/${toggledArchived}`);
            setShowArchivePrompt(false);
            setToggledArchive(null);
            toast.success(data?.message);
        } catch (error) {
            setShowArchivePrompt(false);
            setToggledArchive(null);
            toast.error(error);
        }
    };

    const renderContentContainer = (content) => {
        return <div className="d-flex align-items-center" style={{height: '50px'}}>{content}</div>
    }

    const renderText = (text) => {
        if (!text) return renderContentContainer("-");
        return renderContentContainer(text);
    }

    const renderImage = (imageUrl) => {
        if (!imageUrl) return renderContentContainer("-");
        return <img src={imageUrl} className="d-flex align-items-center rounded-circle" style={{height: '50px', width: '50px'}} />;
    }

    const renderArrayStrings = (array) => {
        if (!array) return renderContentContainer("-");
        return (
            <div className="d-flex align-items-center" style={{height: '50px'}}>
                {array?.map((text, idx) => (
                    <span key={idx}>{array?.length > 1 ? text + ', ' : text}</span>
                ))}
            </div>
        );
    }

    const renderStatus = (status) => {
        return (
            <div className="d-flex align-items-center" style={{height: '50px'}}>
                <div 
                    className={`${status ? 'bg-success' : 'bg-danger'} py-1 px-2 rounded-3 text-white`} 
                    style={{fontSize: '12px'}}
                >
                    {!status ? 'Inactive' : 'Active'}
                </div>
            </div>
        )
    }

    const renderHomeOwnerListRows = () => {
        return (
            homeOwnersList?.homeowners?.map((homeowner, idx) => (
                <tr key={idx}>
                    <td
                        name="House"
                    >
                    {renderImage(homeowner?.house?.picture)}
                    </td>
                    <td
                        name="Account"
                    >
                    {renderText(homeowner?.accountNumber)}
                    </td>
                    <td
                        name="Street"
                    >
                    {renderText(homeowner?.house?.streetNumber + ' ' + homeowner?.house?.streetName)}
                    </td>
                    <td
                        name="Main Homeowner"
                    >
                    {renderText(homeowner?.ownerFirstName + ' ' + homeowner?.ownerLastName)}
                    </td>
                    <td
                        name="Contact number"
                    >
                    {renderArrayStrings(homeowner?.ownerPhones)}
                    </td>
                    <td
                        name="Email"
                    >
                    {renderArrayStrings(homeowner?.ownerEmails)}
                    </td>
                    <td
                        name="Status"
                    >
                    {renderStatus(homeowner?.archived)}
                    </td>
                    <td
                        name="Action"
                        className="d-flex flex-row gap-2 align-items-center"
                    >
                        <div className="d-flex align-items-center" style={{height: '50px'}}>
                            <button
                                type="button"
                                className="bg-primary py-1 d-flex align-items-center border-0 text-white rounded-3"
                            >
                                <i className="material-symbols-outlined">edit_square</i>
                            </button>
                        </div>
                        
                        <Form.Check 
                            className="d-flex align-items-center form-switch form-check-inline"
                            style={{height: '50px'}}
                        >
                            <Form.Check.Input
                                type="checkbox"
                                className="bg-primary"
                                id="homeownerArchiveToggle"
                                checked={homeowner?.archived}
                                onChange={() => setShowArchivePrompt((show) => !show, setToggledArchive(homeowner?.id))}
                            />
                        </Form.Check>
                    </td>
                </tr>
            ))
        )
    }
    
    const renderHomeOwnersList = () => {
        if (homeOwnersList?.homeowners?.length < 1) return <tr><td colSpan={8} className="text-center">No record found</td></tr>;
        return renderHomeOwnerListRows();
    }

    const renderHomeHownersListTable = () => {
        if (loading) return <SpinningBlueCircleLoader />;
        return (
            <table className="config-tasks-list_table table">
                <thead>
                    <tr>
                        <th scope="col" className="config-tasks-list_table--header text-primary">House</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Account</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Street</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Main Homeowner</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Contact Number</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Email</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Status</th>
                        <th scope="col" className="config-tasks-list_table--header text-primary">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {renderHomeOwnersList()}
                </tbody>
            </table>
        );
    }

    return (
        <>
            <Card className="config-tasks-list">
                <div className="config-tasks-list_header">
                    <div className="d-flex flex-row justify-content-between w-100">
                        <div className="d-flex flex-row gap-4">
                            <h5 
                                className={`${listType === 'active' ? 'text-decoration-underline fw-bold' : ''} text-primary`} 
                                style={{cursor: 'pointer'}}
                                onClick={() => setListType('active')}
                            >
                                Homeowners
                            </h5>
                            <h5 
                                className={`${listType === 'archived' ? 'text-decoration-underline fw-bold' : ''} text-primary ms-2`} 
                                style={{cursor: 'pointer'}}
                                onClick={() => setListType('archived')}
                            >
                                Archive
                            </h5>
                        </div>
                        <span className="table-add float-end mb-3 me-2">
                            <Button 
                                className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                                onClick={() => setShowCreateUpdateModal(true)}
                            >
                                <i className="material-symbols-outlined me-1 md-18">add</i>
                                Create Homeowner
                            </Button>
                        </span>
                    </div>
                </div>
                <Card.Body className="pt-0">
                    <div className="table-responsive">
                        {renderHomeHownersListTable()}
                    </div>
                    <div className="pt-4">
                        <Pagination page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage}/>
                    </div>
                </Card.Body>
            </Card>
            <Modal size="sm" show={showArchivePrompt} onHide={() => setShowArchivePrompt(false)}>
                <form onSubmit={onArchiveToggled}>
                    <Modal.Header closeButton>
                        <Modal.Title className="h5">WARNING</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><p>Are you sure you want to archive this homeowner?</p></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" type="button" onClick={() => setShowArchivePrompt(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Confirm
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <CreateUpdateHomeownerModal show={showCreateUpdateModal} setShow={setShowCreateUpdateModal} />
        </>
    )
}

export default HomeOwnersList;