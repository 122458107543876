import React, { useEffect, useState } from "react";
import {
  Row,
  Col
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";

// images
import card1 from "../../assets/images/small/img-1.jpg";
import house from "../../assets/images/user/1.jpg";

// react-slick
import Slider from 'react-slick';
import "../../assets/scss/screens/dashboard/house-list.scss";
import "../../assets/scss/screens/dashboard/tasks-list.scss";

//full calender
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import * as moment from "moment";

const Index = () => {
  const [open, setOpen] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const events= [
    {
      title: "5:30a Click for Google",
      url: "http://google.com/",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-20, "days")
          .format("YYYY-MM-DD") ,
      color: "#50b5ff",
    },
    {
      title: "5:30a All Day Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-18, "days")
          .format("YYYY-MM-DD") ,
      color: "#a09e9e",
    },
    {
      title: "5:30a Long Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-16, "days")
          .format("YYYY-MM-DD"),
      end:
        moment(new Date(), "YYYY-MM-DD")
          .add(-13, "days")
          .format("YYYY-MM-DD") ,
      color: "#49f0d3",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-14, "days")
          .format("YYYY-MM-DD"),
      color: "#ffba68",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-12, "days")
          .format("YYYY-MM-DD") ,
      color: "#d592ff ",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-10, "days")
          .format("YYYY-MM-DD") ,
      color: "#ff9b8a",
    },
    {
      title: "5:30a Birthday Party",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-8, "days")
          .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      title: "5:30a Meeting",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-6, "days")
          .format("YYYY-MM-DD") ,
      color: "#a09e9e",
    },
    {
      title: "5:30a Birthday Party",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-5, "days")
          .format("YYYY-MM-DD") ,
      color: "#49f0d3",
    },
    {
      title: "5:30a Birthday Party",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(-2, "days")
          .format("YYYY-MM-DD") ,
      color: "#ff9b8a ",
    },

    {
      title: "5:30a Meeting",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD"),
      color: "#ff9b8a",
    },
    {
      title: "5:30a Click for Google",
      url: "http://google.com/",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") ,
      color: "#d592ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") ,
      color: "#49f0d3",
    },
    {
      title: "5:30a Birthday Party",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD"),
      color: "#f4a965",
    },
    {
      title: "5:30a Doctor Meeting",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(0, "days")
          .format("YYYY-MM-DD") ,
      color: "#f4a965",
    },
    {
      title: "5:30a All Day Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD") ,
      color: " #50b5ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(8, "days")
          .format("YYYY-MM-DD") ,
      color: " #50b5ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start:
        moment(new Date(), "YYYY-MM-DD")
          .add(10, "days")
          .format("YYYY-MM-DD") ,
      color: "#49f0d3",
    },
  ]

  const navigate = useNavigate();

  const [loadContent, setLoadContent] = useState(true);

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });
  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

  // useEffect(() => {
  //   fetch("http://164.92.112.96:5000/api/v1/users/current", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + localStorage.getItem("token"),
  //     },
  //   }).then((response) => {
  //     if (response.status == 401) {
  //       navigate("/auth/sign-in");
  //     }
  //     return response.json();
  //   });
  // });

  useEffect(() => {
    function handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setTimeout(() => {
          setLoadContent(false);
        }, 2000);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const settings = {
    dots: false,          
    infinite: false,       
    speed: 500,           
    slidesToShow: 3,     
    slidesToScroll: 1,  
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const houses = [
    { id: 1, imgSrc: house, name: 'House 1' },
    { id: 2, imgSrc: house, name: 'House 2' },
    { id: 3, imgSrc: house, name: 'House 3' },
    { id: 4, imgSrc: house, name: 'House 4' },
    { id: 5, imgSrc: house, name: 'House 5' },
    { id: 6, imgSrc: house, name: 'House 6' },
    { id: 7, imgSrc: house, name: 'House 7' },
    { id: 8, imgSrc: house, name: 'House 8' },
    { id: 9, imgSrc: house, name: 'House 9' },
    { id: 10, imgSrc: house, name: 'House 10' }
  ];

  const table4 = [
    {
      row: "1",
      heading1: "Cell",
      heading2: "Cell",
      heading3: "Cell",
      heading4: "Cell",
      heading5: "Cell",
      heading6: "Cell",
      heading7: "Cell",
      heading8: "Cell",
      heading9: "Cell",
    },
    {
      row: "2",
      heading1: "Cell",
      heading2: "Cell",
      heading3: "Cell",
      heading4: "Cell",
      heading5: "Cell",
      heading6: "Cell",
      heading7: "Cell",
      heading8: "Cell",
      heading9: "Cell",
    },
    {
      row: "3",
      heading1: "Cell",
      heading2: "Cell",
      heading3: "Cell",
      heading4: "Cell",
      heading5: "Cell",
      heading6: "Cell",
      heading7: "Cell",
      heading8: "Cell",
      heading9: "Cell",
    },
  ];

  const tableTasksLists = [
    {
      status: "red",
      task: "Approved",
      datePosted: "2024-03-26",
      initiatedBy: "Board member",
      dateDue: "2024-03-26"
    },
    {
      status: "orange",
      task: "Validate",
      datePosted: "2024-03-26",
      initiatedBy: "Board member",
      dateDue: "2024-03-26"
    },
    {
      status: "yellow",
      task: "Submitted",
      datePosted: "2024-03-26",
      initiatedBy: "Board member",
      dateDue: "2024-03-26"
    }
  ]

  return (
    <>
      <Row className="gx-4">
        <Col lg={12}>
          <div className="house-list-container">
            <Slider {...settings}>
              {houses.map(house => (
                <div key={house.id} className="house-list-item">
                  <img src={house.imgSrc} alt={house.name} className="house-list-img" />
                  <div className="house-list-name">{house.name}</div>
                </div>
              ))}
            </Slider>
          </div>
        </Col>
        
        <Col md="8" lg="8">
          <Card >
            <Card.Body>
              <FullCalendar
                  className="calendar-s"
                  initialView="dayGridMonth"
                  contentHeight="auto"
                  eventLimit=" true"
                  dayMaxEvents={1}
                  plugins={[dayGridPlugin, listPlugin, bootstrapPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                  }}
                  events={events}
                />
            </Card.Body>
          </Card>

          <Card className="tasks-list">
            <Card.Header className="tasks-list_header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title text-primary fw-bold">My Tasks</h4>
              </div>
            </Card.Header>
              <Card.Body className="pt-0">
                <div className="table-responsive">
                  <table className="tasks-list_table table">
                    <thead>
                      <tr>
                        <th scope="col" className="tasks-list_table--header text-primary">Status</th>
                        <th scope="col" className="tasks-list_table--header text-primary">Tasks</th>
                        <th scope="col" className="tasks-list_table--header text-primary">Date Posted</th>
                        <th scope="col" className="tasks-list_table--header text-primary">Initiated By</th>
                        <th scope="col" className="tasks-list_table--header text-primary">Date Due</th>
                        <th scope="col" className="tasks-list_table--header text-primary">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableTasksLists.map((item, idx) => (
                        <tr key={idx}>
                          <td>{
                            item.status === "red" ? 
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="8" cy="7.5" r="7.5" fill="#CF2A27"/>
                            </svg>
                           : item.status === "orange" ? 
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="8" cy="7.5" r="7.5" fill="#FF9900"/>
                            </svg>
                            : 
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="8" cy="7.5" r="7.5" fill="#FFFF00"/>
                            </svg>
                          }</td>
                          <td>{item.task}</td>
                          <td>{item.datePosted}</td>
                          <td>{item.initiatedBy}</td>
                          <td>{item.dateDue}</td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
        </Col>

        <Col md="4" lg="4">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title fw-bold text-black">Upcoming Events</h4>
              </div>
            </Card.Header>
            <Card.Body className="pt-0">
              <ul className="m-0 p-0 job-classification list-inline d-flex flex-column gap-3">
                <li className="d-flex flex-row align-items-center gap-4">
                  <div className="d-flex flex-column bg-primary rounded-3 py-2 px-3">
                    <p className="text-white m-0 fw-bold">10</p>
                    <span className="text-white m-0 fw-bold">May</span>
                  </div>
                  <div className="d-flex flex-column">
                    <h5 className="fw-bold">HOA Board Meeting</h5>
                    <span className="">Event Details</span>
                  </div>
                </li>
                <li className="d-flex flex-row align-items-center gap-4">
                  <div className="d-flex flex-column bg-primary rounded-3 py-2 px-3">
                    <p className="text-white m-0 fw-bold">20</p>
                    <span className="text-white m-0 fw-bold">May</span>
                  </div>
                  <div className="d-flex flex-column">
                    <h5 className="fw-bold">Election</h5>
                    <span className="">Event Details</span>
                  </div>
                </li>
                <li className="d-flex flex-row align-items-center gap-4">
                  <div className="d-flex flex-column bg-primary rounded-3 py-2 px-3">
                    <p className="text-white m-0 fw-bold">31</p>
                    <span className="text-white m-0 fw-bold">May</span>
                  </div>
                  <div className="d-flex flex-column">
                    <h5 className="fw-bold">HOA Events</h5>
                    <span className="">Event Details</span>
                  </div>
                </li>
              </ul>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <ul className="m-0 p-0 job-classification list-inline d-flex flex-column gap-3">
                <li className="d-flex flex-row align-items-center rounded-3 p-3" style={{backgroundColor: "#F79F85"}}>
                  <span className="text-white fw-bold w-50">HOA Directory</span>
                  <span className="text-white fw-bold">10</span>
                </li>
                <li className="d-flex flex-row align-items-center rounded-3 p-3" style={{backgroundColor: "#F79F85"}}>
                  <span className="text-white fw-bold w-50">Requests</span>
                  <span className="text-white fw-bold">10</span>
                </li>
                <li className="d-flex flex-row align-items-center rounded-3 p-3" style={{backgroundColor: "#F79F85"}}>
                  <span className="text-white fw-bold w-50">Delinquent</span>
                  <span className="text-white fw-bold">7</span>
                </li>
                <li className="d-flex flex-row align-items-center rounded-3 p-3" style={{backgroundColor: "#F79F85"}}>
                  <span className="text-white fw-bold w-50">Violations</span>
                  <span className="text-white fw-bold">10</span>
                </li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent", right: "-25px" }}
      onClick={onClick}
    >
      <svg width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.307396 1.0575C0.365453 0.999296 0.434423 0.953117 0.510354 0.921609C0.586286 0.890101 0.667687 0.873882 0.749896 0.873882C0.832105 0.873882 0.913507 0.890101 0.989438 0.921609C1.06537 0.953117 1.13434 0.999296 1.1924 1.0575L8.6924 8.5575C8.7506 8.61556 8.79678 8.68453 8.82829 8.76046C8.85979 8.83639 8.87601 8.91779 8.87601 9C8.87601 9.08221 8.85979 9.16361 8.82829 9.23954C8.79678 9.31547 8.7506 9.38444 8.6924 9.4425L1.1924 16.9425C1.07504 17.0599 0.915866 17.1258 0.749896 17.1258C0.583927 17.1258 0.424754 17.0599 0.307396 16.9425C0.190038 16.8251 0.124107 16.666 0.124107 16.5C0.124107 16.334 0.190038 16.1749 0.307396 16.0575L7.36615 9L0.307396 1.9425C0.249192 1.88444 0.203013 1.81547 0.171505 1.73954C0.139997 1.66361 0.123779 1.58221 0.123779 1.5C0.123779 1.41779 0.139997 1.33639 0.171505 1.26046C0.203013 1.18453 0.249192 1.11556 0.307396 1.0575Z" fill="#E5E5E5"/>
      </svg>
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent", left: "-25px" }}
      onClick={onClick}
    >
      <svg width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.69252 1.0575C7.75072 1.11556 7.7969 1.18453 7.82841 1.26046C7.85992 1.33639 7.87613 1.41779 7.87613 1.5C7.87613 1.58221 7.85992 1.66361 7.82841 1.73954C7.7969 1.81547 7.75072 1.88444 7.69252 1.9425L0.633768 9L7.69252 16.0575C7.80988 16.1749 7.87581 16.334 7.87581 16.5C7.87581 16.666 7.80988 16.8251 7.69252 16.9425C7.57516 17.0599 7.41599 17.1258 7.25002 17.1258C7.08405 17.1258 6.92488 17.0599 6.80752 16.9425L-0.692482 9.4425C-0.750686 9.38444 -0.796865 9.31547 -0.828373 9.23954C-0.859881 9.16361 -0.876099 9.08221 -0.876099 9C-0.876099 8.91779 -0.859881 8.83639 -0.828373 8.76046C-0.796865 8.68453 -0.750686 8.61556 -0.692482 8.5575L6.80752 1.0575C6.86557 0.999296 6.93454 0.953117 7.01048 0.921609C7.08641 0.890101 7.16781 0.873882 7.25002 0.873882C7.33223 0.873882 7.41363 0.890101 7.48956 0.921609C7.56549 0.953117 7.63446 0.999296 7.69252 1.0575Z" fill="#E5E5E5"/>
      </svg>
    </div>
  );
};

export default Index;
