import React, { useState, useContext, useMemo, useEffect } from "react";

//router
import { Link, useLocation } from "react-router-dom";

//react-bootstrap
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Nav,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import useDashboardStore from "../../../../zustand/useDashboardStore";
import { stringToURL } from "../../../../utilities/helpers";
import { sidebarIconsMap } from "../../../../utilities/constants";

function CustomToggle({ children, eventKey, onClick }) {
  const {activeEventKey} = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [active, setActive] = useState("");
  const {activeTab, setActiveTab, sidebarMenus} = useDashboardStore();
  let location = useLocation();

  // console.log(active)
  
  // useEffect(() => {
  //   return () => {
  //     setActiveTab(null);
  //     setActiveMenu(false);
  //     setActive("");
  //   };
  // }, [activeTab])

  const renderIcons = ({name, icon, menuName}) => {
    if (name !== menuName) return null;
    return (
      <OverlayTrigger
        key={name}
        placement="right"
        overlay={<Tooltip>{menuName}</Tooltip>}
      >
        <i className="icon material-symbols-outlined">
          {icon}
        </i>
      </OverlayTrigger>
    )
  }

  return (
    <React.Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
      <Nav.Item 
        as="li"
        className="nav-item static-item"
      > 
        <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
          <span className="default-icon">Main</span>
          <span
            className="mini-icon"
            data-bs-toggle="tooltip"
            title="Social"
            data-bs-placement="right"
          >
            -
          </span>
        </Link>
        </Nav.Item>
        {sidebarMenus?.map((menu, idx) => (
          menu?.name === "User Management" ? (
            <>
              <Accordion.Item
                key={idx}
                as="li"
                eventKey="user-menu"
                bsPrefix={`nav-item ${active === "user" ? "active" : ""} `}
                onClick={() => setActive("user")}
              >
                <CustomToggle
                  eventKey="user-menu"
                  onClick={(activeKey) => setActiveMenu(activeKey)}
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{menu?.name}</Tooltip>}
                  >
                    <i className="icon material-symbols-outlined">manage_accounts</i>
                  </OverlayTrigger>
                  <span className="item-name">{menu?.name}</span>
                  <i className="right-icon material-symbols-outlined">
                    chevron_right
                  </i>
                </CustomToggle>
                <Accordion.Collapse eventKey="user-menu">
                  <ul className="sub-nav">
                      {menu?.subLevel?.map((menuDropdown, idx) => (
                        <Nav.Item 
                          as="li" 
                          key={idx}
                          onClick={() => setActiveTab(stringToURL(menuDropdown?.name?.toLowerCase()))}
                        >
                          <Link
                            className={`${
                                activeTab === menuDropdown?.name?.toLowerCase()
                                ? "active"
                                : ""
                            } nav-link`}
                            to={`/user-management?tab=`+stringToURL(menuDropdown?.name?.toLowerCase())}
                          >
                            {sidebarIconsMap.map(({ name, icon }) => renderIcons({name, icon, menuName: menuDropdown?.name}))}
                            <span className="item-name"> {menuDropdown?.name}</span>
                          </Link>
                        </Nav.Item>
                      ))}
                  </ul>
                </Accordion.Collapse>
              </Accordion.Item>
              <Nav.Item 
                as="li"
              >
                <hr className="hr-horizontal" />
              </Nav.Item>
            </>
          ) : (
           <>
            <Nav.Item 
              key={idx}
              as="li"
              className={`${location.pathname === `/`+stringToURL(menu?.name) ? "active" : ""} nav-item `}
              onClick={() => setActiveMenu(false)}
            >
              <Link
                className={`${location.pathname === `/`+stringToURL(menu?.name) ? "active" : ""} nav-link `}
                aria-current="page"
                to={`/`+stringToURL(menu?.name)}
                onClick={() => stringToURL(menu?.name) === 'system-configuration' ? setActiveTab('house') : setActiveTab(activeTab)}
              >
                  {sidebarIconsMap.map(({ name, icon }) => renderIcons({name, icon, menuName: menu?.name}))}
                <span className="item-name">{menu?.name}</span>
              </Link>
            </Nav.Item>
            {idx === 6 && (
              <Nav.Item 
                as="li"
              >
                <hr className="hr-horizontal" />
              </Nav.Item>
            )}
           </>
          )
        ))}
      </Accordion>
    </React.Fragment>
  );
};

export default VerticalNav;
