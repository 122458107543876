// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tasks-list .tasks-list_table .tasks-list_table--header {
  background-color: #DCF0FF;
  text-transform: none !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/screens/dashboard/tasks-list.scss"],"names":[],"mappings":"AAQQ;EACI,yBAAA;EACA,+BAAA;AAPZ","sourcesContent":[".tasks-list {\n\n    .tasks-list_header {\n\n    }\n\n    .tasks-list_table {\n        \n        .tasks-list_table--header {\n            background-color: #DCF0FF;\n            text-transform: none !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
