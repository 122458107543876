import React, { useEffect, useState } from "react";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import useDashboardStore from "../../zustand/useDashboardStore";
import HomeOwnersList from "./tabs/homeOwnersList";
import UsersList from "./tabs/usersList";

const UserManagement = () => {
    const {activeTab, setActiveTab} = useDashboardStore();

    const configTabsMenu = [
        {
            title: "Users",
            icon: "group",
        },
        {
            title: "Roles",
            icon: "account_circle",
        },
        {
            title: "Homeowners",
            icon: "house",
        }
    ]

    const renderTabs = () => {
        switch (activeTab) {
            case "users":
                return <UsersList />;
            case "roles": 
                return <h1>Roles</h1>;
            case "homeowners":
                return <HomeOwnersList />;
            default:
                return null;
        }
    }
    
    return (
        <>
            <Row className="gx-4 py-4">
                <Tab.Container 
                    id="user-management" 
                >
                    <Col lg={12}>
                        <Card>
                            <Card.Body className="p-0">
                                <div className="config-tabs user-tabing item-list-tabs">
                                <Nav
                                    as="ul"
                                    variant="pills"
                                    className="profile-feed-items d-flex align-items-center justify-content-center p-0 m-0 rounded"
                                >
                                    {configTabsMenu.map((tab, idx) => (
                                        <Nav.Item as="li" key={idx} className="col-12 col-sm-1">
                                            <Link
                                                role="button"
                                                className={`${
                                                    activeTab === tab?.title?.toLocaleLowerCase() 
                                                    ? 'active' 
                                                    : ''
                                                } 
                                                d-flex flex-md-column align-items-center flex-row justify-content-center gap-2`}
                                                onClick={() => setActiveTab(tab?.title?.toLocaleLowerCase())}
                                            >
                                                <span className="icon rounded-3">
                                                <span className="material-symbols-outlined">
                                                    {tab.icon}
                                                </span>
                                                </span>
                                                <p className="mb-0 mt-0 mt-md-3">{tab.title}</p>
                                            </Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={12}>
                        <Tab.Content>
                            {renderTabs()}
                        </Tab.Content>
                    </Col>
                </Tab.Container>
            </Row>
        </>
    )
}

export default UserManagement;