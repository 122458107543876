import { 
    Card,
    Form,
    Row,
    Col,
    Modal,
    Button,
    Dropdown
} from "react-bootstrap";
import { useGetApiClient, useUploadPictureApi } from "../../../api/useApiClient";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { HexColorPicker } from "react-colorful";
import defaultUser from "../../../assets/images/user/default_user-gray.png";
import { SpinningBlueCircleLoader, SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import {
    GoogleMap,
    useLoadScript,
    MarkerF,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete"; 

const mapContainerStyle = {
    height: "350px",
};
  
const options = {
    disableDefaultUI: true,
    zoomControl: true,
};

const AddNewHouse = ({setShowAddNewHouse, houseDetails}) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const [useMap, setUseMap] = useState(false);
    const [attachedPicture, setAttachedPicture] = useState(null);
    const [colorCode, setColorCode] = useState("#fff3cd");
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [center, setCenter] = useState({
        lat: -3.745,
        lng: -38.523
    });
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyB0DhlUDvtiZgHU5cXG-lm9-srxSrc0fLs",
        libraries: ["places", "marker"],
    });

    const handleStatusChange = (event) => {
        setStatus(event.target.value); 
    };

    const handleAttachPicture = async (e) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        try {
            const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/configuration/house/upload/picture', formData);
            if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
            setAttachedPicture(uploadedPicture?.directoryPath);
            setLoading(false);
        } catch (error) {
            toast.error(error?.message);
            setLoading(false);
        }
    }

    const handleSubmit = async ({event, type}) => {
        event.preventDefault();
        setLoading(true);
        try {
            if (type === "add") {
                const { data: savedHouse } = await useGetApiClient.post('/admin/configuration/house/save', 
                [
                    {
                        streetNumber:  event.target.streetNumber.value,
                        streetName:  event.target.streetName.value,
                        city:  event.target.city.value,
                        state:  event.target.state.value,
                        zipCode:  event.target.zipCode.value,
                        longitude: center.lng.toString(),
                        latitude: center.lat.toString(),
                        colorCode:  colorCode,
                        status:  status,
                        useMap:  useMap,
                        picture: attachedPicture
                    }
                ]);
                if (savedHouse?.error) throw new Error(savedHouse?.error);
                toast.success(savedHouse?.message);
                setShowAddNewHouse(false);
                setLoading(false);
            } else {
                const { data: updatedHouse } = await useGetApiClient.put(`admin/configuration/house/update/${houseDetails?.id}`, 
                    {
                        streetNumber:  event.target.streetNumber.value,
                        streetName:  event.target.streetName.value,
                        city:  event.target.city.value,
                        state:  event.target.state.value,
                        zipCode:  event.target.zipCode.value,
                        longitude: center.lng.toString(),
                        latitude: center.lat.toString(),
                        colorCode:  colorCode,
                        status:  status,
                        useMap:  useMap,
                        picture: attachedPicture
                    }
                );
                if (updatedHouse?.error) throw new Error(updatedHouse?.error);
                toast.success(updatedHouse?.message);
                setShowAddNewHouse(false);
                setLoading(false);
            }
            
        } catch(error) {
            toast.error(error?.message);
            setLoading(false);
        }
    };

    let prodApiUrl = 'http://api.beneventoeast.net';
    let stageApiUrl = 'https://stage.beneventoapi.evocodes.ph';

    useEffect(() => {
        if (houseDetails?.useMap) setUseMap(houseDetails?.useMap)
        if (houseDetails?.status) setStatus(houseDetails?.status)
        if (houseDetails?.colorCode) setColorCode(houseDetails?.colorCode)
        if (houseDetails?.latitude && houseDetails?.longitude) {
            setCenter({
                lat: Number(houseDetails?.latitude),
                lng: Number(houseDetails?.longitude)
            })
        }
        if (houseDetails?.picture) {
            if (houseDetails?.picture.includes(stageApiUrl)) {
                setAttachedPicture(houseDetails?.picture.replaceAll(stageApiUrl, ""))
            } else {
                setAttachedPicture(houseDetails?.picture.replaceAll(prodApiUrl, ""))
            }
        }
    }, [setStatus, setUseMap, setAttachedPicture, setColorCode, setCenter]);

    if (loadError) return "Error loading maps";
    if (!isLoaded) return <SpinningBlueCircleLoader />;
    return (
        <>
            <Form 
                onSubmit={(event) => handleSubmit({
                    event,
                    type: houseDetails ? "update" : "add"
                })}
            >
                <Card className="config-tasks-list">
                    <div className="config-tasks-list_header">
                        <div className="d-flex flex-row justify-content-between w-100">
                            <h5 className="text-primary">House</h5>
                        </div> 
                    </div>
                    <Card.Body className="pt-0">
                        <Row>
                            <Col lg={5}>
                                <Row className="form-group">
                                    <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="streetNumber">Street Number</label>
                                    <div className="col-sm-8">
                                        <input type="text" defaultValue={houseDetails?.streetNumber} className="form-control" id="streetNumber" name="streetNumber" placeholder="-" required />
                                    </div>
                                </Row>
                                <Row className="form-group">
                                    <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="streetName">Street Name</label>
                                    <div className="col-sm-8">
                                        <input type="text" defaultValue={houseDetails?.streetName} className="form-control" id="streetName" name="streetName" placeholder="-" required />
                                    </div>
                                </Row>
                                <Row className="form-group">
                                    <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="city">City</label>
                                    <div className="col-sm-8">
                                        <input type="text" defaultValue={houseDetails?.city} className="form-control" id="city" name="city" placeholder="-"  />
                                    </div>
                                </Row>
                                <Row className="form-group">
                                    <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="state">State</label>
                                    <div className="col-sm-8">
                                        <input type="text" defaultValue={houseDetails?.state} className="form-control" id="state" name="state" placeholder="-"  />
                                    </div>
                                </Row>
                                <Row className="form-group">
                                    <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="zipCode">ZIP Code</label>
                                    <div className="col-sm-8">
                                        <input type="text" defaultValue={houseDetails?.zipCode} className="form-control" id="zipCode" name="zipCode" placeholder="-" />
                                    </div>
                                </Row>
                                <Row className="form-group">
                                    <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="longitude">Longitude</label>
                                    <div className="col-sm-8">
                                        <input 
                                            type="text" 
                                            defaultValue={center.lng} 
                                            value={center.lng}
                                            onChange={(e) => setCenter({...center, lng: e.target.value})} 
                                            disabled={useMap} 
                                            className="form-control" 
                                            id="longitude" 
                                            name="longitude"
                                            placeholder="-" 
                                        />
                                    </div>
                                </Row>
                                <Row className="form-group">
                                    <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="latitude">Latitude</label>
                                    <div className="col-sm-8">
                                        <input 
                                            type="text" 
                                            defaultValue={center.lat} 
                                            value={center.lat}
                                            onChange={(e) => setCenter({...center, lat: e.target.value})} 
                                            disabled={useMap} 
                                            className="form-control" 
                                            id="latitude"
                                            name="latitude" 
                                            placeholder="-" 
                                        />
                                    </div>
                                </Row>
                                <Row className="form-group">
                                    <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="colorCode">Color code</label>
                                    <div className="col-sm-8">
                                        <button
                                            type="button"
                                            className="d-flex align-items-center py-1 border-0 rounded-3 text-secondary"
                                            onClick={() => setShowColorPicker(true)}
                                            style={{backgroundColor: colorCode}}
                                        >
                                            <i
                                                className="material-symbols-outlined"
                                            >
                                                edit_square
                                            </i>
                                        </button>
                                        <Modal size="sm" show={showColorPicker} onHide={() => setShowColorPicker(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title className="">Pick color code:</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="mx-auto d-flex justify-content-center">
                                                    <HexColorPicker color={colorCode} onChange={setColorCode} />
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" size="sm" onClick={() => setShowColorPicker(false)}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" size="sm" onClick={() => setShowColorPicker(false)}>
                                                    Save
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </Row>
                                <Row className="form-group position-relative">
                                    <label className="control-label col-sm-4 align-self-center mb-0" htmlFor="status">Statuses</label>
                                    <div className="col-sm-8">
                                        <div className="d-flex flex-row gap-3 align-items-center w-100 position-absolute">
                                            <div className="form-check">
                                                <input 
                                                    className="form-check-input border border-primary" 
                                                    type="checkbox" 
                                                    name="status" 
                                                    value="OWNER"
                                                    checked={status === "OWNER"}
                                                    id="owner" 
                                                    onChange={handleStatusChange}
                                                />
                                                <label className="form-check-label" htmlFor="status">
                                                    OWNER
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input 
                                                    className="form-check-input border border-primary" 
                                                    type="checkbox" 
                                                    name="status" 
                                                    value="RENTAL"
                                                    checked={status === "RENTAL"}
                                                    id="rental" 
                                                    onChange={handleStatusChange}
                                                />
                                                <label className="form-check-label" htmlFor="status">
                                                    RENTAL
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </Col>
                            <Col lg={3} className="my-auto">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <div>
                                        {!loading ? (
                                            <img
                                                src={houseDetails?.picture ? houseDetails?.picture : (!attachedPicture ? defaultUser : attachedPicture)}
                                                className="img-fluid"
                                                alt="user"
                                                loading="lazy"
                                            />
                                        ) : <SpinningBlueCircleLoader />}
                                    </div>
                                    <div className="d-flex flex-row gap-3 mt-4">
                                        <div className="d-flex align-items-center">
                                            <label htmlFor="picture" className="d-flex align-items-center" style={{cursor: 'pointer'}}>
                                                <i className="material-symbols-outlined">attach_file</i>
                                            </label>
                                            <input
                                                id="picture"
                                                name="picture"
                                                type="file"
                                                accept="image/*"
                                                className="d-none"
                                                onChange={(e) => handleAttachPicture(e)}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="border-0 bg-transparent d-flex align-items-center text-danger"
                                            onClick={() => setAttachedPicture(null)}
                                        >
                                            <i className="material-symbols-outlined">delete</i>
                                        </button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="d-flex flex-column gap-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="form-check">
                                            <input 
                                                className="form-check-input border border-primary" 
                                                type="checkbox" 
                                                name="useMap" 
                                                value="useMap"
                                                checked={useMap}
                                                id="useMap" 
                                                onChange={() => setUseMap((useMap) => !useMap)}
                                            />
                                            <label className="form-check-label" htmlFor="useMap">
                                                Use map
                                            </label>
                                        </div>
                                        <div>
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="25" height="25" rx="5" fill="#58BBFE"/>
                                                <path d="M8.28125 18.5938H16.7188" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M12.5 12.9688C13.5355 12.9688 14.375 12.1293 14.375 11.0938C14.375 10.0582 13.5355 9.21875 12.5 9.21875C11.4645 9.21875 10.625 10.0582 10.625 11.0938C10.625 12.1293 11.4645 12.9688 12.5 12.9688Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.1875 11.0938C17.1875 15.3125 12.5 18.5938 12.5 18.5938C12.5 18.5938 7.8125 15.3125 7.8125 11.0938C7.8125 9.85055 8.30636 8.65826 9.18544 7.77919C10.0645 6.90011 11.2568 6.40625 12.5 6.40625C13.7432 6.40625 14.9355 6.90011 15.8146 7.77919C16.6936 8.65826 17.1875 9.85055 17.1875 11.0938V11.0938Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                        {useMap ? <SearchMap setCenter={setCenter} houseDetails={houseDetails} /> : <></>}
                                        <GoogleMap
                                            mapContainerStyle={mapContainerStyle}
                                            zoom={18}
                                            center={center}
                                            options={options}
                                        >
                                        {center && <MarkerF position={center} />}
                                        </GoogleMap>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Form.Check className="form-group d-flex align-items-center justify-content-center">
                    <button type="submit" className="btn btn-primary w-50 ">
                        {!loading ? 'Save' : <SpinningWhiteCircleLoader />}
                    </button>
                </Form.Check>
            </Form>
        </>
    )
}

const SearchMap = ({ setCenter, houseDetails }) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
        location: { lat: () => Number(houseDetails?.latitude), lng: () => Number(houseDetails?.longitude) },
            radius: 200 * 1000, 
        },
    });
    
    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();
    
        try {
          const results = await getGeocode({ address });
          const { lat, lng } = await getLatLng(results[0]);
          setCenter({ lat, lng });
        } catch (error) {
          toast.error(error)
        }
    };
    
    return (
        <div className="search position-relative pb-4">
            <input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
                placeholder="Search a location"
                className="border p-2 rounded-3 w-100"
            />
            {status === "OK" && (
                <ul 
                    className="position-absolute z-3"
                    style={{ listStyleType: "none", padding: "0", marginTop: "0", border: "1px solid #ccc" }}
                >
                    {data.map(({ place_id, description }) => (
                        <li
                            key={place_id}
                            className="bg-white"
                            onClick={() => handleSelect(description)}
                            style={{ padding: "10px", cursor: "pointer", borderBottom: "1px solid #ccc" }}
                        >
                            {description}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default AddNewHouse;