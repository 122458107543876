import axios from "axios";

const token = localStorage.getItem("token");

export const useGetApiClient = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        'Authorization': `Bearer ${token}` 
    }
})

export const useUploadPictureApi = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
    },
})