import {
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
  } from "react-bootstrap";
import Card from "../../components/Card";
import { useState } from "react";
import useGetHouseListApi from "../../hooks/system-configuration/useGetHouseListApi";
import "../../assets/scss/screens/dashboard/tasks-list.scss";
import { SpinningBlueCircleLoader } from "../../components/common/loaders";

const SystemConfiguration = () => {
    const [activeTab, setActiveTab] = useState(0);

    const { loading, houseList } = useGetHouseListApi();

    const configTabsMenu = [
        {
            title: "House",
            icon: "home",
            active: true,
        },
        {
            title: "Billing",
            icon: "attach_money",
            active: false,
        }
    ]
    
    const renderHouseListTable = () => {
        if (loading) return <SpinningBlueCircleLoader />
        return (
            <table className="tasks-list_table table">
                <thead>
                    <tr>
                        <th scope="col" className="tasks-list_table--header text-primary">House Name</th>
                        <th scope="col" className="tasks-list_table--header text-primary">Status</th>
                        <th scope="col" className="tasks-list_table--header text-primary">Color Code</th>
                        <th scope="col" className="tasks-list_table--header text-primary">Long-Lat</th>
                        <th scope="col" className="tasks-list_table--header text-primary">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {houseList?.houses?.map((house, idx) => (
                        <tr key={idx}>
                            <td
                            name="houseName"
                            // onBlur={handleAddFormChanges}
                            >
                            {house?.houseName}
                            </td>
                            <td
                            name="status"
                            // onBlur={handleAddFormChanges}
                            >
                            {house?.status}
                            </td>
                            <td
                            name="colorCode"
                            // onBlur={handleAddFormChanges}
                            >
                            {house?.colorCode}
                            </td>
                            <td
                            name="longLat"
                            // onBlur={handleAddFormChanges}
                            >
                            {house?.longLat}
                            </td>
                            <td
                            name="action"
                            // onBlur={handleAddFormChanges}
                            >
                            </td>
                            {/* <td>
                            <a href="">
                                <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Update</Tooltip>}
                                >
                                <i className="material-symbols-outlined me-1 md-18 text-primary">
                                    assignment
                                </i>
                                </OverlayTrigger>{" "}
                            </a>
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    return (
        <>
            <Row className="gx-4">
                <Col lg={12}>
                    <div
                        className="d-flex flex-row"
                    >
                        {configTabsMenu.map((tab, idx) => (
                            <div
                                key={idx}
                                onClick={() => setActiveTab(idx)}
                                className="pointer-event"
                            >
                                <Card className="px-4">
                                    <Card.Body>
                                        <div className={`${activeTab === idx ? 'bg-primary text-white' : 'bg-primary-subtle'} rounded-3 align-items-center justify-content-center d-flex p-3`}>
                                            <i className="icon material-symbols-outlined">{tab.icon}</i>
                                        </div>
                                        
                                        <p className={`${activeTab === idx ? 'text-primary' : ''} pt-3 text-center`}>{tab.title}</p>
                                    </Card.Body>
                                </Card>
                            </div>  
                        ))}
                    </div>
                </Col>
                <Col lg={12}>
                    <Card className="tasks-list">
                        <Card.Header className="tasks-list_header">
                            <div className="d-flex flex-row justify-content-between w-100">
                                <h5 className="text-primary">House</h5>
                                <span className="table-add float-end mb-3 me-2">
                                    <Button className="btn btn-sm btn-success d-flex align-items-center rounded-3">
                                        <i
                                        className="material-symbols-outlined me-1 md-18"
                                        // onClick={addNewValue}
                                        >
                                        add
                                        </i>
                                        Add House
                                    </Button>
                                </span>
                            </div>
                            
                        </Card.Header>
                        <Card.Body className="pt-0">
                            <div className="table-responsive">
                                {renderHouseListTable()}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default SystemConfiguration;